import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "settings" }
const _hoisted_2 = { class: "settings__wrap" }
const _hoisted_3 = { class: "settings__menu" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "settings__copy-container" }
const _hoisted_6 = { class: "settings__copy-button" }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = {
  key: 0,
  class: "settings__copied"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AdminPanelNavMenu = _resolveComponent("AdminPanelNavMenu")!
  const _component_Copy = _resolveComponent("Copy")!
  const _component_Select = _resolveComponent("Select")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_AdminPanelNavMenu),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", {
          class: _normalizeClass(["settings__menu-item", { 'active-menu': _ctx.schoolCodeTemplate }]),
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setActiveTemplate('schoolCode')))
        }, " School code ", 2),
        _createElementVNode("div", {
          class: _normalizeClass(["settings__menu-item", { 'active-menu': _ctx.freeTrialTemplate }]),
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setActiveTemplate('freeTrial')))
        }, " Free trial ", 2)
      ]),
      (_ctx.schoolCodeTemplate)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _cache[6] || (_cache[6] = _createElementVNode("div", { class: "settings__sub-title" }, "Edit school code", -1)),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", {
                class: "settings__copy-input",
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.copyText()))
              }, _toDisplayString(_ctx.generateStateCode), 1),
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_component_Copy, {
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.copyText()))
                })
              ])
            ]),
            _createElementVNode("button", {
              class: "settings__button",
              onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.generateInviteCode && _ctx.generateInviteCode(...args)))
            }, " Re-generate code ")
          ]))
        : _createCommentVNode("", true),
      (_ctx.freeTrialTemplate)
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
            _cache[7] || (_cache[7] = _createElementVNode("div", { class: "settings__sub-title" }, "Free trial duration", -1)),
            _createVNode(_component_Select, {
              data: _ctx.dataTrial,
              "select-name": "30 days",
              class: "settings__select",
              onOptionSelected: _ctx.handleOptionSelectedTrial,
              title: 'title',
              clearActive: false
            }, null, 8, ["data", "onOptionSelected"]),
            _createElementVNode("button", {
              class: "settings__button",
              onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.updateSchool && _ctx.updateSchool(...args)))
            }, "Active")
          ]))
        : _createCommentVNode("", true)
    ]),
    (_ctx.copied)
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, "Code copied!"))
      : _createCommentVNode("", true)
  ]))
}