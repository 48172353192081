
import { defineComponent } from "vue";
import Select from "@/components/atoms/CustomSelect.vue";
import Copy from "@/assets/svg/copy-instructor.svg?inline";
import AdminPanelNavMenu from "@/components/molecules/AdminPanelNavMenu.vue";
export default defineComponent({
  name: "Settings",
  data() {
    return {
      schoolCodeTemplate: true,
      freeTrialTemplate: false,
      dataTrial: [
        {
          title: "0",
          value: "0",
        },
        {
          title: "7",
          value: "7",
        },
        {
          title: "14",
          value: "14",
        },
        {
          title: "30",
          value: "30",
        },
        {
          title: "60",
          value: "60",
        },
      ],
      trialPer: "30",
      generateStateCode: "",
      copied: false,
      school: null,
    };
  },
  components: {
    Select,
    Copy,
    AdminPanelNavMenu,
  },
  mounted() {
    this.school = JSON.parse(localStorage.getItem("sra-school"));
    this.generateStateCode = this.school.code
  },
  methods: {
    setActiveTemplate(template: string) {
      if (template === "schoolCode") {
        this.schoolCodeTemplate = true;
        this.freeTrialTemplate = false;
      } else if (template === "freeTrial") {
        this.schoolCodeTemplate = false;
        this.freeTrialTemplate = true;
      }
    },
    handleOptionSelectedTrial(selectedOption) {
      this.trialPer = selectedOption.value;
    },
    updateSchool(data) {
      data = {
        id: this.school.id,
        free_trial_days_interval: this.trialPer,
      };
      this.$store.dispatch("admins/adminMySchoolUpdate", data).then(
        (res) => {
          console.log(res, "a");
        },
        (error) => {
          this.errorInviteCode = error.response.data.message;
        }
      );
    },
    generateInviteCode(data) {
      let instructorsData = [];
      instructorsData.push(this.instructorId);
      data = {
        instructors: instructorsData,
        school_id: localStorage.getItem("admin-school-id")
      };
      this.$store.dispatch("instructors/generateInviteCode", data).then(
        (res) => {
          this.generateStateCode = res.data.code;

          localStorage.setItem("sra-school", JSON.stringify({ code: res.data.code, name: this.school.name }));
          window.location.reload();
        },
        (error) => {
          this.errorInviteCode = error.response.data.message;
        }
      );
    },
    copyText() {
      const textToCopy = this.generateStateCode;
      const textarea = document.createElement("textarea");
      textarea.value = textToCopy;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand("copy");
      document.body.removeChild(textarea);
      this.copied = true;

      setTimeout(() => {
        this.copied = false;
      }, 3000);
    },
  },
});
